import React from 'react';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';
import Hero from '../components/hero/hero';
import Layout from '../components/layout';

const FaqsPage = () => (
  <Layout title="FAQs">
    <Hero backgroundImage="zoo-pharm" headline="FAQs" tagline="" />
    <section className="content-wrapper zoo-pharm-banner">
      <Grid justifyContent="center" alignItems="center">
        <GridItem md={8}>
          <h2>ZooPharm FAQ’s</h2>
          <h3>How do I prescribe and/or place an order to or through ZooPharm?</h3>
          <ul>
            <li>
              <strong>If you are a veterinarian…</strong>
              <ol>
                <li>
                  You can fax a prescription for the ZooPharm formulation you desire to our pharmacy
                  at
                  <strong> (307) 761-6060.</strong>
                </li>
                <li>
                  If this is your first time ordering with ZooPharm, please include a copy of your
                  current State Veterinary license.
                </li>
                <li>
                  In addition, when ordering DEA controlled substances please also include a copy of
                  your current Personal “Practitioner” DEA Controlled Substance Registration
                  Certificate.
                </li>
                <li>
                  When completing a prescription form, please ensure that your Rx specifies the
                  patient name OR single species for whom the medication is being prescribed, in
                  compliance with your State Board of Pharmacy requirements.
                </li>
                <li>
                  If prescribing for more than one patient/species, multiple prescriptions will be
                  required.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <strong>If you are an animal owner…</strong>
              <ol>
                <li>
                  All ZooPharm orders require a prescription from a licensed veterinarian with a
                  valued client – patient relationship
                </li>
                <li>
                  When you are ready to order, please ask your veterinarian to fax us a prescription
                  for the desired medication to (307) 761-6060, noting one species/patient per Rx as
                  required by your state Board of Pharmacy.
                </li>
                <li>
                  If your veterinarian has previously prescribed for you authorizing refills, you
                  may request refills online or contact us directly with your prescription
                  information for order processing assistance at 866 823 9314. Please note,
                  prescriptions for controlled substances such as BAM and Buprenorphine SR are valid
                  for six months.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <strong>
                What are the additional requirements for ordering controlled substances from
                ZooPharm?
              </strong>
              <ol>
                <li>
                  DEA controlled substances compounded by ZooPharm (such as Buprenorphine SR, BAM
                  Kits, and other scheduled Class II, III and IV drug formulations) are available
                  upon prescription from a licensed veterinarian.
                </li>
                <li>
                  To order, a veterinarian must fax a prescription for the formulation, noting one
                  species/patient per Rx as required by your state Board of Pharmacy, as well as a
                  copy of his/her current DEA “Practitioner” controlled substance certificate to
                  (307) 761-6060. (This is only required if we do not already have a copy of your
                  current DEA certificate on file.)
                </li>
                <li>
                  Be sure the Rx notes the total quantity desired and number of refills, if
                  applicable, noting one patient/species per prescription.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <strong>Notes for Class II controlled substances…</strong>
              <ol>
                <li>
                  When ordering Class II controlled substances such as Etorphine or Thianil, listing
                  on the DEA Carfentanil Approved User List is required. This is noted on your DEA
                  registration under Business Activity.
                </li>
                <li>
                  For Thianil only, DEA 222 forms must be addressed and submitted directly to our
                  pharmacy at: ZooPharm, 3131 Grand Ave, Unit C, Laramie WY 82070. Etorphine is a
                  compounded formulation and provided upon prescription.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <strong>
                As a licensed veterinarian, can I legally have compounded medications that I
                prescribe through ZooPharm sent directly to my clients/patients?
              </strong>
              <ol>
                <li>
                  Compounded formulations available through ZooPharm cannot be legally dispensed or
                  resold.
                </li>
                <li>ZooPharm must bill and ship directly to your client.</li>
                <li>
                  In addition, please ensure your Rx specifies the patient name OR single species
                  for whom the medication is being prescribed, in compliance with your State
                  requirements.
                </li>
                <li>
                  If you have authorized refills for your client’s prescription, they may refill
                  their prescriptions directly online or contact us directly for assistance at 866
                  823 9314
                </li>
                <li>
                  Please ask the person your Rx is written for to call our business office at
                  866-823-9314 to set up his/her account and confirm billing & shipping details for
                  subsequent orders.
                </li>
                <li>
                  They are advised to wait least 3-4 hours after submitting order documents with
                  contact and payment details for account set-up.
                </li>
                <li>We accept all major credit cards, prepaid check or ACH transfer.</li>
              </ol>
            </li>
            <br />
            <li>
              <strong>What are the ZooPharm guidelines for shipping orders?</strong>
              <ol>
                <li>
                  We are committed to providing the highest standards in customer service. This
                  includes, when possible, shipping prescriptions the day they are received and
                  confirmed. We do our best to accommodate emergency shipments. In general, we are
                  able to process for shipment within 48 hours during our high-volume seasons.
                </li>
                <li>We ship orders to you via FedEx delivery.</li>
                <li>
                  Based on your preferences, formulation sensitivity and weather, we can ship orders
                  for standard ground delivery, 2-day delivery and overnight delivery. Saturday
                  delivery, while location dependent, may be an option.
                </li>
                <li>Please note we cannot deliver to PO Boxes.</li>
                <li>All orders require signature for delivery.</li>
              </ol>
            </li>
          </ul>
        </GridItem>
      </Grid>
    </section>
  </Layout>
);

export default FaqsPage;
